@font-face {
    font-family: 'MontAS';
    font-variant: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('MontAS-Regular'), url('./fonts/MontAS-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'MontAS';
    font-variant: 'Bold';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('MontAS-Bold'), url('./fonts/MontAS-Bold.otf') format('opentype');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'MontAS', 'Roboto', sans-serif;
    background-color: #fafafa;
}
